import React, { useState, useEffect } from "react";
import { Button, Checkbox, Col, ColorPicker, Form, InputNumber, Radio, Drawer, Row, Select, message, Space, Switch, Upload, Input, Divider, DatePicker, Tooltip } from "antd";
import "../../../assets/styles/admin.css";
import axios from "axios";
import api from "../../../services/api";
import { Outlet, Link } from "react-router-dom";
import { MinusCircleOutlined, PlusOutlined, ArrowUpOutlined, DesktopOutlined, MobileOutlined, FileImageOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import { useForm } from "antd/es/form/Form";
import config from "../../../services/config";
import endpoints from "../../../services/endpoints";
import "react-quill/dist/quill.snow.css";
import dayjs from "dayjs";
const { Dragger } = Upload;

const { Option } = Select;
const { TextArea } = Input;

function Guidelines({ open, close, data, questionIndex }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [filePreview, setFilePreview] = useState([]);
  const [guides, setGuides] = useState([]);
  const [calculators, setCalculators] = useState([]);

  const [form] = useForm();

  useEffect(() => {
    if (open) {
      handleGetData();
      if (data) {
        form.setFieldsValue(data);
      }
    }
  }, [open]);

  function handleGetData() {
    axios
      .get(endpoints.clinicalCases.read)
      .then((res) => {
        console.log(res);
        setCalculators(res.data[3]);
        setGuides(res.data[4]);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleSubmit(values) {
    console.log(values);

    let guidelines = values.guidelines;
    for (let i = 0; i < guidelines.length; i++) {
      if (guidelines[i].type === "image") {
        if (guidelines[i].image && guidelines[i].image.fileList && guidelines[i].image.fileList.length > 0) {
          guidelines[i].image = guidelines[i].image.fileList[0].response;
        } else if (guidelines[i].image && guidelines[i].image.file && guidelines[i].image.file.status === "removed") {
          guidelines[i].image = null;
        } else {
          guidelines[i].image = guidelines[i].image;
        }

        if (guidelines[i].image_mobile && guidelines[i].image_mobile.fileList && guidelines[i].image_mobile.fileList.length > 0) {
          guidelines[i].image_mobile = guidelines[i].image_mobile.fileList[0].response;
        } else if (guidelines[i].image_mobile && guidelines[i].image_mobile.file && guidelines[i].image_mobile.file.status === "removed") {
          guidelines[i].image = null;
        } else {
          guidelines[i].image_mobile = guidelines[i].image_mobile;
        }
      }

      if (guidelines[i].type === "video") {
        if (guidelines[i].image && guidelines[i].image.fileList && guidelines[i].image.fileList.length > 0) {
          guidelines[i].image = guidelines[i].image.fileList[0].response;
        } else if (guidelines[i].image && guidelines[i].image.file && guidelines[i].image.file.status === "removed") {
          guidelines[i].image = null;
        } else {
          guidelines[i].image = guidelines[i].image;
        }
      }
    }

    close(values);
    form.resetFields();
  }

  return (
    <Drawer
      className="drawer-clinical"
      key={`drawer-create-questions-clinical-guidelines`}
      title="Guidelines - Caso clínico"
      width={900}
      onClose={() => close()}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={
        <>
          <Button disabled={isButtonLoading} className="mr-10" onClick={() => close()}>
            Cancelar
          </Button>
          <Button loading={isButtonLoading} type="primary" onClick={form.submit}>
            Submeter
          </Button>
        </>
      }
    >
      <Row gutter={[24]} className="m-0">
        <Col span={24}>
          <Form form={form} onFinish={handleSubmit} layout="vertical">
            <Form.List name={"guidelines"}>
              {(fields, { add, remove, move }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <div style={{ position: "relative" }}>
                      <Col span={24}>
                        <Form.Item
                          {...field}
                          label="Tipo"
                          name={[index, "type"]}
                          rules={[
                            {
                              required: true,
                              message: "Este campo é obrigatório",
                            },
                          ]}
                        >
                          <Select
                            size="large"
                            showSearch
                            placeholder="Tipo de content"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                            options={[
                              { value: "image", label: "Imagem" },
                              { value: "references", label: "Referências" },
                              { value: "text", label: "Texto" },
                              { value: "calculator", label: "Calculadora" },
                              { value: "guide", label: "Guia" },
                              { value: "video", label: "Video" },
                              { value: "cta", label: "CTA" },
                            ].map((item) => ({
                              value: item.value,
                              label: item.label,
                            }))}
                            getPopupContainer={(trigger) => trigger.parentNode}
                          />
                        </Form.Item>

                        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues !== currentValues}>
                          {({ getFieldValue }) => {
                            if (getFieldValue("guidelines") && getFieldValue("guidelines")[index]) {
                              if (getFieldValue("guidelines")[index].type === "references") {
                                return (
                                  <Form.Item
                                    label="Referências"
                                    name={[index, "references"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Este campo é obrigatório",
                                      },
                                    ]}
                                  >
                                    <ReactQuill placeholder="Referências..." size="large" />
                                  </Form.Item>
                                );
                              } else if (getFieldValue("guidelines")[index].type === "text") {
                                return (
                                  <Form.Item
                                    label="Texto"
                                    name={[index, "text"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Este campo é obrigatório",
                                      },
                                    ]}
                                  >
                                    <ReactQuill placeholder="Referências..." size="large" />
                                  </Form.Item>
                                );
                              } else if (getFieldValue("guidelines")[index].type === "image") {
                                return (
                                  <Row gutter={[24]}>
                                    <Col span={12}>
                                      <Form.Item
                                        label="Imagem desktop"
                                        name={[index, "image"]}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Este campo é obrigatório",
                                          },
                                        ]}
                                      >
                                        <Dragger
                                          accept="image/*"
                                          multiple={false}
                                          maxCount={1}
                                          defaultFileList={
                                            getFieldValue("guidelines")[index].image
                                              ? [
                                                  {
                                                    name:
                                                      getFieldValue("guidelines")[index].image.fileList && getFieldValue("guidelines")[index].image.fileList.length > 0
                                                        ? getFieldValue("guidelines")[index].image.fileList[0].response
                                                        : getFieldValue("guidelines")[index].image,
                                                  },
                                                ]
                                              : []
                                          }
                                          action={config.server_ip + endpoints.media.singleUpload}
                                          className={`dragger ${getFieldValue("guidelines")[index].image ? "dragger_hidden" : ""}`}
                                        >
                                          <p className="ant-upload-drag-icon">
                                            <DesktopOutlined
                                              style={{
                                                color: "rgb(103 103 103)",
                                              }}
                                            />
                                          </p>
                                          <p className="ant-upload-text text">
                                            <b>Upload da imagem em desktop</b>
                                          </p>
                                          <p className="ant-upload-hint">Faça Drag and drop aqui</p>
                                          <div
                                            className={`preview_file ${getFieldValue("guidelines")[index].image ? "" : "hidden"}`}
                                            style={{
                                              backgroundImage: `url(${
                                                getFieldValue("guidelines")[index].image
                                                  ? `${config.server_ip}/assets/media/${
                                                      getFieldValue("guidelines")[index].image.fileList && getFieldValue("guidelines")[index].image.fileList.length > 0
                                                        ? getFieldValue("guidelines")[index].image.fileList[0].response
                                                        : getFieldValue("guidelines")[index].image
                                                    }`
                                                  : null
                                              })`,
                                            }}
                                          ></div>
                                        </Dragger>
                                      </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                      <Form.Item
                                        label="Imagem mobile"
                                        name={[index, "image_mobile"]}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Este campo é obrigatório",
                                          },
                                        ]}
                                      >
                                        <Dragger
                                          accept="image/*"
                                          multiple={false}
                                          maxCount={1}
                                          defaultFileList={
                                            getFieldValue("guidelines")[index].image_mobile
                                              ? [
                                                  {
                                                    name:
                                                      getFieldValue("guidelines")[index].image_mobile.fileList &&
                                                      getFieldValue("guidelines")[index].image_mobile.fileList.length > 0
                                                        ? getFieldValue("guidelines")[index].image_mobile.fileList[0].response
                                                        : getFieldValue("guidelines")[index].image_mobile,
                                                  },
                                                ]
                                              : []
                                          }
                                          action={config.server_ip + endpoints.media.singleUpload}
                                          className={`dragger ${getFieldValue("guidelines")[index].image_mobile ? "dragger_hidden" : ""}`}
                                        >
                                          <p className="ant-upload-drag-icon">
                                            <MobileOutlined
                                              style={{
                                                color: "rgb(103 103 103)",
                                              }}
                                            />
                                          </p>
                                          <p className="ant-upload-text text">
                                            <b>Upload da imagem em mobile</b>
                                          </p>
                                          <p className="ant-upload-hint">Faça Drag and drop aqui</p>
                                          <div
                                            className={`preview_file ${getFieldValue("guidelines")[index].image_mobile ? "" : "hidden"}`}
                                            style={{
                                              backgroundImage: `url(${
                                                getFieldValue("guidelines")[index].image_mobile
                                                  ? `${config.server_ip}/assets/media/${
                                                      getFieldValue("guidelines")[index].image_mobile.fileList &&
                                                      getFieldValue("guidelines")[index].image_mobile.fileList.length > 0
                                                        ? getFieldValue("guidelines")[index].image_mobile.fileList[0].response
                                                        : getFieldValue("guidelines")[index].image_mobile
                                                    }`
                                                  : null
                                              })`,
                                            }}
                                          ></div>
                                        </Dragger>
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                );
                              } else if (getFieldValue("guidelines")[index].type === "calculator") {
                                return (
                                  <Form.Item
                                    {...field}
                                    label="Calculadora"
                                    name={[index, "calculator"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Este campo é obrigatório",
                                      },
                                    ]}
                                  >
                                    <Select
                                      size="large"
                                      showSearch
                                      placeholder="Calculadora..."
                                      optionFilterProp="children"
                                      filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                                      options={calculators.map((item) => ({
                                        value: item.id,
                                        label: item.name,
                                      }))}
                                      getPopupContainer={(trigger) => trigger.parentNode}
                                    />
                                  </Form.Item>
                                );
                              } else if (getFieldValue("guidelines")[index].type === "guide") {
                                return (
                                  <Form.Item
                                    {...field}
                                    label="Guia"
                                    name={[index, "guide"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Este campo é obrigatório",
                                      },
                                    ]}
                                  >
                                    <Select
                                      size="large"
                                      showSearch
                                      placeholder="Guia..."
                                      optionFilterProp="children"
                                      filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                                      options={guides.map((item) => ({
                                        value: item.id,
                                        label: item.title,
                                      }))}
                                      getPopupContainer={(trigger) => trigger.parentNode}
                                    />
                                  </Form.Item>
                                );
                              } else if (getFieldValue("guidelines")[index].type === "cta") {
                                return (
                                  <>
                                    <Form.Item
                                      {...field}
                                      label="Título"
                                      name={[index, "title"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Este campo é obrigatório",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Título..." size="large" />
                                    </Form.Item>
                                    <Form.Item
                                      {...field}
                                      label="Texto"
                                      name={[index, "text"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Este campo é obrigatório",
                                        },
                                      ]}
                                    >
                                      <ReactQuill placeholder="Texto..." size="large" />
                                    </Form.Item>
                                    <Form.Item
                                      {...field}
                                      label="Texto do botão"
                                      name={[index, "button_text"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Este campo é obrigatório",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Texto do botão..." size="large" />
                                    </Form.Item>
                                    <Form.Item
                                      {...field}
                                      label="Link do botão"
                                      name={[index, "button_link"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Este campo é obrigatório",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Link do botão..." size="large" />
                                    </Form.Item>
                                  </>
                                );
                              } else if (getFieldValue("guidelines")[index].type === "video") {
                                return (
                                  <>
                                    <Form.Item
                                      {...field}
                                      label="Título"
                                      name={[index, "title"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Este campo é obrigatório",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Título..." size="large" />
                                    </Form.Item>
                                    <Form.Item
                                      {...field}
                                      label="Texto"
                                      name={[index, "text"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Este campo é obrigatório",
                                        },
                                      ]}
                                    >
                                      <ReactQuill placeholder="Texto..." size="large" />
                                    </Form.Item>
                                    <Form.Item
                                      {...field}
                                      label="Texto do botão"
                                      name={[index, "button_text"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Este campo é obrigatório",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Texto do botão..." size="large" />
                                    </Form.Item>
                                    <Form.Item
                                      {...field}
                                      label="Link do botão"
                                      name={[index, "button_link"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Este campo é obrigatório",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Link do botão..." size="large" />
                                    </Form.Item>
                                    <Form.Item
                                      label="Imagem"
                                      name={[index, "image"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Este campo é obrigatório",
                                        },
                                      ]}
                                    >
                                      <Dragger
                                        accept="image/*"
                                        multiple={false}
                                        maxCount={1}
                                        defaultFileList={
                                          getFieldValue("guidelines")[index].image
                                            ? [
                                                {
                                                  name:
                                                    getFieldValue("guidelines")[index].image.fileList && getFieldValue("guidelines")[index].image.fileList.length > 0
                                                      ? getFieldValue("guidelines")[index].image.fileList[0].response
                                                      : getFieldValue("guidelines")[index].image,
                                                },
                                              ]
                                            : []
                                        }
                                        action={config.server_ip + endpoints.media.singleUpload}
                                        className={`dragger ${getFieldValue("guidelines")[index].image ? "dragger_hidden" : ""}`}
                                      >
                                        <p className="ant-upload-drag-icon">
                                          <FileImageOutlined
                                            style={{
                                              color: "rgb(103 103 103)",
                                            }}
                                          />
                                        </p>
                                        <p className="ant-upload-text text">
                                          <b>Upload da imagem</b>
                                        </p>
                                        <p className="ant-upload-hint">Faça Drag and drop aqui</p>
                                        <div
                                          className={`preview_file ${getFieldValue("guidelines")[index].image ? "" : "hidden"}`}
                                          style={{
                                            backgroundImage: `url(${
                                              getFieldValue("guidelines")[index].image
                                                ? `${config.server_ip}/assets/media/${
                                                    getFieldValue("guidelines")[index].image.fileList && getFieldValue("guidelines")[index].image.fileList.length > 0
                                                      ? getFieldValue("guidelines")[index].image.fileList[0].response
                                                      : getFieldValue("guidelines")[index].image
                                                  }`
                                                : null
                                            })`,
                                          }}
                                        ></div>
                                      </Dragger>
                                    </Form.Item>
                                  </>
                                );
                              }
                            }
                          }}
                        </Form.Item>
                      </Col>
                      <div className="d-flex jc-center ai-center" style={{ position: "absolute", top: 0, right: 0 }}>
                        {index !== 0 && fields.length > 1 ? (
                          <Tooltip title="Mover" placement="bottom">
                            <ArrowUpOutlined className="f-20 mr-10" onClick={() => move(index, index - 1)} />
                          </Tooltip>
                        ) : null}
                        {fields.length > 1 ? (
                          <Tooltip title="Apagar">
                            <MinusCircleOutlined className="f-20 mr-10" onClick={() => remove(field.name)} />
                          </Tooltip>
                        ) : null}
                      </div>
                      <Divider
                        style={{
                          height: 1,
                          backgroundColor: "rgba(0,0,0,0.2)",
                        }}
                      />
                    </div>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} className="w-100" size="large" icon={<PlusOutlined />}>
                      Adicionar item
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>
        </Col>
      </Row>
    </Drawer>
  );
}

export default Guidelines;
